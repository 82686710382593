import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const signupUser = async (payload, setLoading) => {

  try {
    await http.post(AUTH_ROUTES.SIGNUP, payload);
    setLoading(false);
    successNotifier(
      "Successfully Signed up, please check your mail for verification link "
    );
    window.location.pathname = "/login";
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
  }
};
